<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'BranchNew' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="branches.itemsPerPage" id="perPageSelect" size="sm" :options="branches.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="branches.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="branches.fields" :filter="branches.filter" :current-page="branches.currentPage"
                 :per-page="branches.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="branches.sortBy" :sort-desc="branches.sortDesc"
                 ref="branchesTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in branches.fields" :key="field.key"
                          :class="branches.filters[index] && branches.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="branches.filters[index]">
                            <b-col v-if="branches.filters[index].inputType === 'Select'">
                                <b-select v-model="branches.filters[index].value">
                                    <b-select-option value="">Todos</b-select-option>
                                    <b-select-option value="TRUE">Activo</b-select-option>
                                    <b-select-option value="FALSE">Inactivo</b-select-option>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="branches.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="branches.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" v-if="row.item.enabled" class="p-1">Activo</b-badge>
                <b-badge variant="warning" v-else>Inactivo</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'BranchShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" :to="{ name: 'BranchEdit', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Modificar" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover :title="row.item.enabled ? 'Desactivar' : 'Activar'"
                              variant="outline-primary" @click="changeStatus(row.item)">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ branches.totalRows }} registro<template v-if="branches.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="branches.currentPage" :total-rows="branches.totalRows"
                                      :per-page="branches.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { branchListUrl, branchEnableUrl } from '@routes';
import * as constants from '@constants';
import tableStateMixin from '../../mixins/tableState';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            branches: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'name', label: 'Nombre', sortable: true },
                    { key: 'enabled', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 'b.name', type: 'STRING', value: '', format: '' },
                    { column: 'b.enabled', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Sucursales',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.branches, this.tableState);
    },
    methods: {
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.branches.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(branchListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.branches.totalRows = response.data.totalRows;
                        return response.data.branches;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        changeStatus(branch) {
            this.$swal({
                title: `¿${branch.enabled ? 'Desactivar' : 'Activar'} la sucursal ${branch.name}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(branchEnableUrl(), {
                        branchId: branch.id,
                        enable: !branch.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            branch.enabled = !branch.enabled;

                            this.$bvToast.toast(response.data.message, {
                                title: 'Sucursales',
                                variant: 'success'
                            });

                            this.$refs.branchesTable.refresh();
                            this.$emit('get-setting');
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar el estatus.', {
                            title: 'Sucursales',
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.branches.sortBy = context.sortBy;
            this.branches.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.branches);
        }
    },
    watch: {
        'branches.filters': {
            handler() {
                this.$refs.branchesTable.refresh();
                this.saveTableState(this.$route.name, this.branches);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.branches.totalRows === 0 ? 0 : (this.branches.currentPage - 1) * this.branches.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.branches.currentPage - 1) * this.branches.itemsPerPage;

            return (this.branches.totalRows - offset) < this.branches.itemsPerPage ? this.branches.totalRows : offset + this.branches.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>